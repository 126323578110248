import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const Editor = ({ cargarDescripcion, descripcion }) => {
  const handleChange = (value) => {
    cargarDescripcion(value);
  };
  

  return <ReactQuill theme="snow" value={descripcion} onChange={handleChange} />;
};
