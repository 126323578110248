import React from "react";
import { Link } from "react-router-dom";
// import Anteojo from './Anteojo';

export const Item = ({
  id = "",
  titulo = "",
  imagenes = "",
  precio = "",
  stock = "",
}) => {
  const tituloId = titulo.replace(/_|#| |@|<>/g, "_");
  console.log(stock);

  return (
    <div className="tienda" key={id}>
      <Link to={`/detalle/${id}#${tituloId}`} className="link">
        <div className="cajaDeCajas">
          <div className="imgMedida caja">
            <div className="prueba img-container">
              <img
                src={imagenes[0].url}
                alt={`Foto de ${titulo}`}
                className="img-fluid mx-0 px-0"
              />
              <div className={stock === 0 ? "medida" : "d-none"}>
                <h5>sin stock</h5>
                <p>consultar</p>
              </div>
            </div>
          </div>
          <h4 className="text-center negrita mt-2">{titulo}</h4>
          <p className="text-center negrita pb-3">
            <span className="negrita me-2">
              ${precio.toLocaleString("es-Es")}{" "}
            </span>
          </p>
        </div>
      </Link>
    </div>
  );
};
